import moment from "moment-timezone";

const zoneData = require("moment-timezone/data/meta/latest.json");
let zones = [];

for (const zone in zoneData.zones) {
  zones.push(zone);
}

zones.sort();

console.log(zones.length);

// 将date字符串转成指定时区的时间
function dateParse(dateStr, zone) {
  if (!zone) {
    zone = currentTimezone();
  }

  return moment(dateStr)
    .tz(zone)
    .format("yyyy-MM-DD HH:mm:ss");
}

// 将本地date对象或者带时区的date字符串转成北京时间
function dateParse2Bj(date) {
  return dateParse(date, "Asia/Shanghai");
}

//格式化成日期，不带时间
function formatToDate(dateStr){

  if (typeof dateStr === 'undefined' || dateStr === null||dateStr === '') {
    return "";
  }
  else  
    return moment(dateStr).format("yyyy-MM-DD");
}

// 返回本地时区
function currentTimezone() {
  return moment.tz.guess(true);
}

export { formatToDate, dateParse, dateParse2Bj, currentTimezone, zones };

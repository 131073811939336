import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import userApi from "@/apis/user";
import { currentTimezone } from "@/utils/date";

export default new Vuex.Store({
  state: {
    userSetting: {},
  },
  mutations: {
    userSetting(state, userSetting) {
      state.userSetting = userSetting;
    },
  },
  actions: {
    async getUserSetting({ commit }) {
      try {
        const res = await userApi.getUserSetting();
        if (!res) {
          commit("userSetting", {
            timezone: currentTimezone(),
            distanceUnit: 0,
          });
        } else {
          commit("userSetting", res);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    userSetting(state) {
      return state.userSetting;
    },
  },
  modules: {},
});

import Vue from "vue";
import locale from "element-ui/lib/locale";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.lang || navigator.language,
  fallbackLocale: "en",
  messages: {},
});

// 预装默认语言
const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  // 实现element插件的多语言切换
  locale.i18n((key, value) => i18n.t(key, value));
  return lang;
}

function loadLanguageAsync(newLang) {
  const lang = newLang || localStorage.lang || navigator.language;
  if (i18n.locale !== lang || !loadedLanguages.includes(lang)) {
    return import(/* webpackChunkName: "lang-[request]" */ `./langs/${lang}`)
      .then((msgs) => {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);
        localStorage.lang = lang;
        return setI18nLanguage(lang);
      })
      .catch((err) => {
        console.log(`当前还未适配该语言：${lang}，使用默认语言`, err);
        localStorage.lang = "en";
        return setI18nLanguage("en");
      });
  }
  if (!localStorage.lang) {
    localStorage.lang = lang;
  }
  return Promise.resolve(lang);
}
export default i18n;
export { loadLanguageAsync };

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",  // 若需要以params 方式传参 则需要配置name属性
    meta: {
      // 添加该字段，表示进入这个路由是需要登录的
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Index.vue"),
      // props : (route) => ({
      //   id : route.query.groupId,
      //   loginType : route.query.loginType,
      // })
  },
  {
    path: "/Group",
    name: "Group",
    meta: {
      // 添加该字段，表示进入这个路由是需要登录的
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Group.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    props: (route) => ({ imei: route.query.imei }),
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () =>
      import(
        /* webpackChunkName: "forget-password" */ "../views/ForgetPassword.vue"
      ),
    props: (route) => ({ imei: route.query.imei }),
  },
  {
    path: "/history",
    name: "History",
    meta: {
      // 添加该字段，表示进入这个路由是需要登录的
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "history" */ "../views/History.vue"),
    props: (route) => ({ imei: route.query.imei }),
  },
  {
    path: "/follow",
    name: "Follow",
    meta: {
      // 添加该字段，表示进入这个路由是需要登录的
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "follow" */ "../views/Follow.vue"),
    props: (route) => ({ imei: route.query.imei }),
  },
  {
    path: "/fence",
    name: "Fence",
    meta: {
      // 添加该字段，表示进入这个路由是需要登录的
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "fence" */ "../views/Fence.vue"),
    props: (route) => ({ imei: route.query.imei }),
  },
  {
    path: "/manage",
    name: "Manage",
    meta: {
      // 添加该字段，表示进入这个路由是需要登录的
      requireAuth: true,
    },
    component: () =>
    import(/* webpackChunkName: "manage" */ "../views/Manage.vue"),
    props: (route) => ({ imei: route.query.imei }),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
  },

  {
    path: "/Test",
    name: "AudioTest",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/AudioTest.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//https://router.vuejs.org/zh/
// 全局前置守卫
// to: Route: 即将要进入的目标 路由对象
// from: Route: 当前导航正要离开的路由
// next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.token) {
      // 获取当前的token是否存在
      next();
    } 
    else {
      next({
        path: "/login",
        query: { redirect: to.fullPath }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } 
  else {
    next();
  }
});

export default router;

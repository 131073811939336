import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";
import i18n from "@/locales/i18n";

//在请求或响应被 then 或 catch 处理前拦截它们。
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("token");
    // token && (config.headers.Authorization = token);
    // const userId = localStorage.getItem("userId");
    // userId && (config.headers.UserId = userId);
    // //debugger
    // //if(loginType=="group"){
    //   const loginType=localStorage.getItem('loginType');
    //   if(loginType=="group")
    //   loginType && (config.headers.loginType = loginType);
    //   //config.headers.orgId = storage.getItem('SGQ.global.userAuthor').orgId
    // //}
    const loginType=localStorage.getItem('loginType');
    // //debugger
    if(loginType=="group"){
      const token = localStorage.getItem("groupToken");
      token && (config.headers.Authorization = token);
      const userId = localStorage.getItem("groupUserId");
      userId && (config.headers.UserId = userId);
      //console.log("group..........userId["+userId+"]"+"token["+token+"]");
      //debugger
    }
    else {
      const token = localStorage.getItem("token");
      token && (config.headers.Authorization = token);
      const userId = localStorage.getItem("userId");
      userId && (config.headers.UserId = userId);
      //console.log("group..........loginType"+loginType+"userId"+localStorage.getItem("userId")+"token["+localStorage.getItem("token")+"]");
      //debugger
    }
    // const token = localStorage.getItem("token");
    // token && (config.headers.Authorization = token);
    // const userId = localStorage.getItem("userId");
    // userId && (config.headers.UserId = userId);

    //debugger
    return config;
    
  },
  (err) => {
    debugger
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (data) => {
    return data.data;
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case (401, 403):
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          localStorage.removeItem("groupToken");
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
          });
          break;
        default: {
          const message =
            err.response.data.message ||
            i18n.t("error." + err.response.data.code) ||
            "The server is error!";
          Message({ message, center: true });
        }
      }
    }
    return Promise.reject(err.response.data);
  }
);

axios.defaults.headers = {
  "Content-Type": "application/json;charset=UTF-8",
};

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

export default class Http {
  prefix = "";
  constructor(prefix) {
    this.prefix = prefix;
  }

  get(url, params, headers = {}) {
    return axios.get(this.prefix + url, {
      params,
      headers,
    });
  }

  post(url, data, headers = {}) {
    return axios.post(this.prefix + url, data, headers);
  }

  put(url, data, headers = {}) {
    return axios.put(this.prefix + url, data, headers);
  }

  patch(url, data, headers = {}) {
    return axios.patch(this.prefix + url, data, headers);
  }

  delete(url, data, headers = {}) {
    return axios.delete(this.prefix + url, { data: data }, headers);
  }

  getDownload(url, params, headers = {}) {
    return axios.get(this.prefix + url, {
      params,
      headers,
      //responseType:'blob',
      //responseType: "arraybuffer",
    });
  }
}




import Http from "./index";

const http = new Http("api/user");

export default {
  async login(data) {
    const res = await http.post("/login", data);
    return res.data;
  },
  async imeilogin(data) {
    const res = await http.post("/imeilogin", data);
    return res.data;
  },
  async register(data) {
    const res = await http.post("/register", data);
    return res.data;
  },

  async resetPassword(data) {
    const res = await http.patch("/password/reset", data);
    return res.data;
  },

  async changePassword(data) {
    const res = await http.patch("/password", data);
    return res.data;
  },

  async userInfo() {
    const res = await http.get("/info");
    return res.data;
  },

  async getUserSetting() {
    const res = await http.get("/setting");
    return res.data;
  },

  async sendCode(data) {
    const res = await http.post("/code", data);
    return res.data;
  },

  async updateUserSetting(data) {
    const res = await http.put("/setting", data);
    return res.data;
  },
};
